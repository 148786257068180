<template>
    <div>
        <div class="relative flex border bg-white rounded-2xl shadow-lg items-center">
            <div class="absolute -right-1 -top-1">
                <div class="relative w-full h-full">
                    <div class="bg-red-500 h-4 w-4 rounded-full animate-ping"></div>
                    <div
                        class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 bg-red-500 h-5 w-5 rounded-full"
                    ></div>
                </div>
            </div>

            <div v-if="tempGameStore.hasGame" class="flex flex-col w-full py-4 justify-center items-center">
                <span class="text-center text-gray-500 font-medium text-sm">
                    {{ tempGameStore?.tempGame?.data?.sport }}
                </span>

                <div class="flex flex-col">
                    <div class="flex w-full justify-between gap-10 items-center">
                        <div class="flex flex-col w-full">
                            <span class="font-bold text-5xl text-black">
                                {{ tempGameStore?.tempGame?.data?.teams[0]?.score }}
                            </span>
                            <span class="font-medium text-sm">
                                {{ tempGameStore?.tempGame?.data?.teams[0]?.name }}
                            </span>
                        </div>

                        <div>
                            <span class="font-bold text-xl text-brand">{{ liveMinutes }}</span>
                            <span class="font-bold text-xl text-brand">:</span>
                            <span class="font-bold text-xl text-brand">{{ liveSeconds }}</span>
                        </div>

                        <div class="flex flex-col w-full">
                            <span class="font-bold text-5xl text-black">
                                {{ tempGameStore?.tempGame?.data?.teams[1]?.score }}
                            </span>
                            <span class="font-medium text-sm">
                                {{ tempGameStore?.tempGame?.data?.teams[1]?.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

const tempGameStore = useTempGameStore();
const currentTime = ref(new Date()); // Track current time to force reactivity

// Function to update current time
function updateCurrentTime() {
    currentTime.value = new Date();
}

// Create an interval to update the current time every second
onMounted(() => {
    const interval = setInterval(() => {
        updateCurrentTime();
    }, 1000);

    // Clean up the interval when the component is unmounted
    onUnmounted(() => {
        clearInterval(interval);
    });
});

const gameClock = computed(() => tempGameStore?.tempGame?.data?.game_clock);

const liveMinutes = computed(() => {
    if (gameClock.value?.is_running && gameClock.value?.started_at) {
        const startedAt = new Date(gameClock.value.started_at).getTime();
        const now = currentTime.value.getTime(); // Use reactive current time

        const elapsedSeconds = Math.floor((now - startedAt) / 1000);
        const totalTimeInSeconds = gameClock.value.minutes * 60 + gameClock.value.seconds;

        const remainingTimeInSeconds = totalTimeInSeconds - elapsedSeconds;
        const minutes = Math.floor(remainingTimeInSeconds / 60);

        return minutes >= 0 ? minutes : 0;
    }

    return gameClock.value?.minutes ?? 0;
});

const liveSeconds = computed(() => {
    if (gameClock.value?.is_running && gameClock.value?.started_at) {
        const startedAt = new Date(gameClock.value.started_at).getTime();
        const now = currentTime.value.getTime(); // Use reactive current time

        const elapsedSeconds = Math.floor((now - startedAt) / 1000);
        const totalTimeInSeconds = gameClock.value.minutes * 60 + gameClock.value.seconds;

        const remainingTimeInSeconds = totalTimeInSeconds - elapsedSeconds;
        const seconds = remainingTimeInSeconds % 60;

        return seconds >= 0 ? seconds : 0;
    }

    return gameClock.value?.seconds ?? 0;
});
</script>

<style scoped></style>
